<template>
  <div class="container" style="overflow-y: hidden" v-loading="loading.all">
    <div class="card">
      <div class="template-head">
        <div class="title">
          <div class="btn" @click="$router.back()">
            <el-icon><arrow-left /></el-icon>
            <span>返回</span>
          </div>
          <span>{{ info.name }}</span>
          <el-tag
            v-if="info.transplant"
            class="tag"
            size="small"
            effect="dark"
            >{{ info.transplantName }}</el-tag
          >
          <el-tag
            v-if="info.based === 1"
            class="tag"
            size="small"
            effect="dark"
            type="success"
            >基于手术日期</el-tag
          >
          <el-tag
            v-else-if="info.based === 2"
            class="tag"
            size="small"
            effect="dark"
            type="success"
            >基于出院日期</el-tag
          >
        </div>
        <div>
          <el-button type="primary" @click="save">保存变更</el-button>
          <el-button type="danger" @click="remove">删除模板</el-button>
        </div>
      </div>
    </div>
    <div style="display: flex">
      <div class="card data-card mb-0" v-loading="loading.panel">
        <div style="height: 100%">
          <div class="sub-title">
            <div>随访节点</div>
            <el-button type="primary" size="small" @click="switchModel('add')">
              <el-icon><plus /></el-icon>
              <span>添加随访节点</span>
            </el-button>
          </div>
          <el-cascader-panel
            v-model="select"
            class="cascader"
            :options="list"
            @expand-change="selectChange"
            @change="selectChange"
          >
            <template #default="{ node, data }">
              <div class="line1 cascader-item">
                {{ data.label }}
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </div>
            </template>
          </el-cascader-panel>
        </div>
      </div>
      <div class="card edit-card ml-10 mb-0" v-loading="loading.form">
        <div class="sub-title mt-5" style="margin-bottom: 15px">
          <div v-if="item.model === 'edit'">节点详情</div>
          <div v-else-if="item.model === 'add'">添加节点</div>
        </div>
        <div v-if="item.form.day !== undefined">
          <el-form label-width="70px">
            <el-form-item label="距离天数">
              {{ info.based == 1 ? "术后" : "出院" }}
              <el-input-number
                v-model="item.form.day"
                step-strictly
                :min="0"
                :max="18250"
                class="ml-10 mr-10"
              />天
            </el-form-item>
            <el-form-item label="节点名称">
              <el-input
                v-model="item.form.label"
                class="mr-10"
                style="width: 180px"
              ></el-input>
              <el-button class="ml-10" @click="buildName" plain
                >根据天数生成</el-button
              >
            </el-form-item>
            <el-form-item label="宽限期限" class="mb-15">
              向前<el-input-number
                v-model="item.form.prefix"
                step-strictly
                :min="0"
                :max="365"
                class="ml-10 mr-10"
                style="width: 120px"
              />天, 向后
              <el-input-number
                v-model="item.form.suffix"
                step-strictly
                :min="0"
                :max="365"
                class="ml-10 mr-10"
                style="width: 120px"
              />天
            </el-form-item>
            <el-form-item label="全面复查" class="mb-15">
              <el-switch
                v-model="item.form.overall"
                inline-prompt
                active-text="是"
                inactive-text="否"
              />
            </el-form-item>
            <el-form-item label="备注说明" class="mb-15">
              <el-input
                v-model="item.form.remarks"
                class="mr-10"
                type="textarea"
                :rows="2"
                resize="none"
                maxlength="110"
                placeholder="患者可见, 最多110个字符"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-transfer
            v-model="item.form.index"
            filterable
            :titles="['可选检测项', '已选检测项']"
            :format="{
              noChecked: '${total}',
              hasChecked: '${checked}/${total}',
            }"
            :props="{
              key: 'id',
              label: 'name',
            }"
            :data="group"
          />
          <div class="mt-10" v-if="item.model === 'edit'">
            <el-button type="danger" @click="removeItem">删除</el-button>
            <el-button
              class="float-right"
              type="primary"
              style="width: 100px"
              @click="saveItem"
              >修改</el-button
            >
          </div>
          <div class="mt-10" v-else-if="item.model === 'add'">
            <el-button @click="switchModel('edit')" plain>取消</el-button>
            <el-button
              class="float-right"
              type="primary"
              style="width: 100px"
              @click="addItem"
              >添加</el-button
            >
          </div>
        </div>
        <el-empty
          v-else
          description="请先选择具体的随访节点"
          style="margin: 170px 0 174px 0"
        ></el-empty>
      </div>
      <div class="card readme-card ml-10 mb-0 hidden-md-and-down">
        <div class="sub-title mt-5 mb-15">常见问题</div>
        <div class="mb-10">
          <div>Q: 系统是如何根据天数生成节点名称的?</div>
          <div>
            A: 首先会基于“手术日期”或“出院日期”生成前缀,
            然后根据距离天数生成后缀.
          </div>
        </div>
        <div class="mb-10">
          <div>Q: 生成的节点名称中时间单位是如何标定的?</div>
          <div>A: 其中 1 年为 365 天, 半年为 180 至 185 天, 1 月为 30 天.</div>
        </div>
        <div class="mb-10">
          <div>Q: 距离天数是如何定义的?</div>
          <div>
            A: 这是从模板设定的“基础时间(手术或出院日期)”次日开始计算的天数.
          </div>
        </div>
        <div class="mb-10">
          <div>Q: 宽限期限是什么意思?</div>
          <div>
            A: 理想情况下患者在计划当天进行随访, 当实际生活中患者往往会偏差几天,
            宽限期限就是限制允许偏差的天数, 这个天数不含计划随访日当天.
          </div>
        </div>
        <div class="mb-10">
          <div>Q: 全面复查有什么作用?</div>
          <div>
            A: 开启全面复查后, 用户会在此条随访计划上看到特别提示,
            并且系统多渠道多次通知患者及时复查.
          </div>
        </div>
        <div class="mb-10">
          <div>Q: 备注说明可以写些什么内容?</div>
          <div>
            A: 此说明是提供给患者的, 显示在患者随访计划条目下,
            请不要填写于本次随访无关的内容.
          </div>
        </div>
        <div class="mb-10">
          <div>Q: 术后/出院1年半为什么会被划分在第2年?</div>
          <div>
            A: 这是因为1年只有365天, 而1年半有五百四十多天, 所以会被分在下一年.
          </div>
        </div>
        <div>
          <div>Q: 我对模板做了改动要如何保存?</div>
          <div>
            A:
            在页面中进行“修改”、“添加”和“删除”操作后需要点击右上角的”保存变更“才会正式保存.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeft, Plus } from "@element-plus/icons";
import { common, follow } from "../../plugin/api";

export default {
  name: "TemplateInfo",
  components: { ArrowLeft, Plus },
  data: () => ({
    loading: {
      panel: true,
      form: true,
      all: false,
    },
    select: [],
    group: [],
    cache: "",
    list: [],
    item: {
      model: "edit",
      select: [],
      form: {},
    },
    info: {
      id: 0,
      name: "随访模板详情",
      disabled: true,
    },
  }),
  methods: {
    init() {
      this.info.id = this.$route.params.id;
      this.initCache();
      this.getInfo();
    },
    initCache() {
      let cache = localStorage.getItem("cache_index_group");
      if (cache) this.group = JSON.parse(cache);
      common.initPage("/template/info").then((res) => {
        if (res.state) {
          localStorage.setItem("cache_index_group", JSON.stringify(res.data));
          this.loading.form = false;
          this.group = res.data;
        }
      });
    },
    getInfo() {
      follow
        .getTemplate(this.info.id)
        .then((res) => {
          if (res.state) {
            this.cache = JSON.stringify(res.data.content);
            this.info = res.data;
            this.buildList();
            this.loading.panel = false;
          }
        })
        .catch(() => {
          this.loading.panel = false;
          this.$message.error("网络错误");
        });
    },
    buildList() {
      let list = [];
      let cache = {};
      let content = this.info.content;
      // 按照距离天数重新排序
      content.sort(function (a, b) {
        return a.day - b.day;
      });
      for (let i in content) {
        let day = content[i].day;
        // 计算年份
        let year = parseInt(day % 365 === 0 ? day / 365 : day / 365 + 1);
        let tag = "第" + year + "年";
        content[i].value = parseInt(i) + 1;
        if (cache[tag] === undefined) cache[tag] = [content[i]];
        else cache[tag].push(content[i]);
      }
      for (let key in cache) {
        list.push({
          value: key,
          label: key,
          children: cache[key],
        });
      }
      this.list = list;
    },
    buildName() {
      let name = "";
      let prefix = "术后";
      if (this.info.based === 2) prefix = "出院";
      let day = this.item.form.day;
      if (day === 0) name = prefix + "当天";
      else if (day < 365) name = prefix + day + "天";
      else {
        let year = parseInt(day / 365);
        day = day % 365;
        let month = parseInt(day / 30);
        if (day >= 180 && day < 185) name = prefix + year + "年半";
        else if (day === 0) name = prefix + year + "年";
        else if (day % 30 === 0) name = prefix + year + "年零" + month + "个月";
        else name = prefix + year + "年零" + day + "天";
      }
      this.item.form.label = name;
    },
    selectChange(e) {
      if (e[1]) {
        let json = JSON.stringify(this.info.content[e[1] - 1]);
        this.item = {
          select: e,
          model: "edit",
          form: JSON.parse(json),
        };
      } else {
        this.select = e;
        this.item = {
          model: "edit",
          select: e,
          form: {},
        };
      }
    },
    switchModel(model) {
      if (model === "add") {
        this.item.form = {
          day: 0,
          index: [],
          label: "",
          overall: false,
          prefix: 0,
          remarks: "",
          suffix: 0,
        };
      } else {
        if (this.item.select[1] != undefined)
          this.selectChange(this.item.select);
        else this.selectChange([0]);
      }
      this.item.model = model;
    },
    getIndex() {
      let number = 0;
      for (let a in this.list) {
        let year = this.list[a].value;
        for (let b in this.list[a].children) {
          number++;
          if (this.list[a].children[b].day === this.item.form.day)
            return [year, number];
        }
      }
      return undefined;
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    addItem() {
      if (this.item.form.label.trim() === "")
        return this.showWarning("节点名称不能为空");
      if (this.item.form.index.length === 0)
        return this.showWarning("检测项目不能为空");
      if (this.getIndex() === undefined) {
        this.select = [];
        this.loading.form = true;
        this.info.content.push(this.item.form);
        this.$message.success({
          message: "添加成功, 点击’保存变更‘后生效",
          center: true,
        });
        this.buildList();
        setTimeout(() => {
          this.select = this.getIndex();
          this.selectChange(this.select);
          this.loading.form = false;
        }, 300);
      } else {
        this.showWarning("当前距离天数已存在");
      }
    },
    saveItem() {
      let edit = false;
      let old = this.info.content[this.item.select[1] - 1];
      for (let key in this.item.form) {
        if (
          (key !== "index" && this.item.form[key] !== old[key]) ||
          (key === "index" &&
            JSON.stringify(this.item.form[key]) !== JSON.stringify(old[key]))
        )
          edit = true;
      }
      if (edit) {
        this.loading.form = true;
        this.info.content[this.item.select[1] - 1] = this.item.form;
        this.buildList();
        this.select = [];
        this.$message.success({
          message: "节点详情修改成功, 点击’保存变更‘后生效",
          center: true,
        });
        setTimeout(() => {
          this.select = this.getIndex();
          this.selectChange(this.select);
          this.loading.form = false;
        }, 300);
      } else {
        this.showWarning("节点详情没有变动");
      }
    },
    removeItem() {
      this.$confirm(
        "删除后只要未点击右上角的‘保存变更’就可以通过刷新页面来找回节点.",
        "删除节点",
        {
          confirmButtonText: "确认删除",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.info.content.splice(this.item.select[1] - 1, 1);
        this.item.select.pop();
        this.item.form = {};
        this.buildList();
        this.$message.success({
          message: "节点删除成功, 点击’保存变更‘后生效",
          center: true,
        });
      });
    },
    save() {
      let cacheV2 = JSON.parse(JSON.stringify(this.info.content));
      for (let i in cacheV2) cacheV2[i].value = undefined;
      if (this.cache !== JSON.stringify(cacheV2)) {
        this.$confirm(
          "您修改了模板内容, 是否保存变更, 保存后将无法撤回!",
          "保存变更",
          {
            confirmButtonText: "确认保存",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.loading.all = true;
          follow
            .updateTemplateContent(this.info.id, JSON.stringify(cacheV2))
            .then((res) => {
              setTimeout(() => {
                this.loading.all = false;
                if (res.state) {
                  this.$message.success({
                    message: "保存成功",
                    center: true,
                  });
                } else {
                  this.showWarning(
                    res.message ? res.message : "发生意料之外的错误"
                  );
                }
              }, 500);
            })
            .catch(() => {
              this.loading.all = false;
              this.$message.error("网络错误");
            });
        });
      } else this.showWarning("模板内容没有变动");
    },
    remove() {
      this.$confirm("确认要删除“" + this.info.name + "”吗", "删除模板", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        follow
          .removeTemplate(this.info.id)
          .then((res) => {
            setTimeout(() => {
              if (res.state) {
                this.$router.back();
                this.$message.success({
                  message: "删除成功",
                  center: true,
                });
              } else {
                this.showWarning("删除失败,发生意料之外的错误");
              }
            }, 300);
          })
          .catch(() => {
            this.$message.error("网络错误");
          });
      });
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name === "TemplateInfo") this.init();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.template-head {
  justify-content: space-between;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  width: 100%;
}

.template-head .title {
  align-items: center;
  display: flex;
}

.template-head .title > span {
  margin-left: 10px;
  font-size: 18px;
}

.template-head .tag {
  font-size: 12px !important;
  margin-top: 2px;
}

.btn {
  background-color: #f4f4f4;
  font-size: 16px;
  color: #222;
}

.btn:hover {
  background-color: #e2e2e2;
  color: #222;
}

.btn:active,
.btn.active {
  background-color: #d4d3d3;
  color: #222;
}

.data-card {
  height: calc(100vh - 105px);
  align-items: center;
  display: flex;
}

.sub-title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  line-height: 16px;
  font-size: 16px;
  display: flex;
  color: #222;
}

.cascader {
  height: calc(100vh - 145px);
  justify-content: center;
  width: 360px;
}

.cascader-item {
  display: block;
  width: 125px;
}

.edit-card {
  height: max-content;
  width: 445px;
}

.readme-card {
  width: calc(100vw - 1055px);
  height: max-content;
  font-size: 14px;
  color: #555;
}
</style>

<style>
.cascader .el-cascader-menu__wrap {
  height: calc(100vh - 145px);
  width: 180px;
}

.edit-card .el-transfer__buttons {
  padding: 0 2.5px;
  width: 40px;
}

.edit-card .el-transfer__buttons > button {
  padding: 8px 12px;
  margin: 5px 0;
}

.edit-card .el-transfer-panel__filter {
  margin: 5px;
}

.edit-card .el-transfer-panel__filter > input {
  border-radius: 6px;
}

.edit-card .el-transfer-panel__list {
  height: calc(100% - 40px);
}

.edit-card .el-transfer-panel__header .el-checkbox__label {
  font-size: 14px !important;
}
</style>